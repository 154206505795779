import React from "react"
import comicGreenBG from "../images/comic-green-background-small.svg"
import { Link } from "gatsby"

const WebReviewForm = () => {
  return (
    <>
      <div id="contact" className="anchorid"></div>
      <section className="container mx-auto text-center pt-4 px-3">
        <div className="comic">
          <div
            className="panel lg:flex-1 xl:w-1/2"
            style={{
              backgroundImage: "url(" + comicGreenBG + ")",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <div className="flex justify-center px-6 my-12">
              <div className="w-full xl:w-3/4 lg:w-11/12 flex">
                <div className="w-full bg-white p-5 rounded-lg">
                  <p className="heading-box-text1 text-2xl sm:text-4xl lg:text-5xl pt-4 text-center">
                    <span>FREE Website Performance Review</span>
                  </p>
                  <p className="pt-6 italic">FREE website review covering Performance, Best Practices, Accessibility and SEO…</p>
                  <form
                    className="px-2 sm:px-8 pt-6 pb-4 mb-4 bg-white rounded"
                    name="performance-review-request"
                    method="post"
                    data-netlify="true"
                    action="/success/"
                  >
                    <input type="hidden" name="form-name" value="performance-review-request" />
                    <div className="mb-4 md:flex md:justify-between">
                      <div className="mb-4 md:mr-2 md:mb-0">
                        <label
                          className="block mb-2 text-xl text-gray-700"
                          style={{ fontFamily: "Roboto Mono, monospace" }}
                        >
                          First Name
                          <input
                            className="w-full px-3 py-2 text-lg leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            name="firstname"
                            id="firstName"
                            type="text"
                            placeholder="First Name"
                            required
                          />
                        </label>
                      </div>
                      <div className="md:ml-2">
                        <label
                          className="block mb-2 text-xl text-gray-700"
                          style={{ fontFamily: "Roboto Mono, monospace" }}
                        >
                          Last Name
                          <input
                            className="w-full px-3 py-2 text-lg leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            name="lastname"
                            id="lastName"
                            type="text"
                            placeholder="Last Name"
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label
                        className="block mb-2 text-xl text-gray-700"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                      >
                        Email
                        <input
                          className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Contact Email"
                          required
                        />
                      </label>
                    </div>
                    <div className="mb-4">
                      <label
                        className="block mb-2 text-xl text-gray-700"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                      >
                        Telephone
                        <input
                          className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          name="telephone"
                          id="telephone"
                          type="tel"
                          placeholder="Contact number"
                        />
                      </label>
                    </div>

                    <div className="mb-4">
                      <label
                        className="block mb-2 text-xl text-gray-700"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                      >
                        Your Website URL
                        <input
                          className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          name="website"
                          id="website"
                          placeholder="www.example.com"
                          required
                        />
                      </label>
                    </div>

                    <div className="mb-4">
                      <label
                        className="block mb-2 text-xl text-gray-700 text-left"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                      >
                        <input
                          className="mr-3"
                          name="gdpr"
                          id="gdpr"
                          type="checkbox"
                          required
                        />
                        <small>In order to submit your details to us, please provide consent to the terms of our <Link to="/privacy-policy/">Privacy Policy</Link></small>
                      </label>
                    </div>
                    <div className="mb-1 text-center">
                      <button
                        className="w-2/3 px-4 py-2 text-2xl sm:text-4xl text-white tracking-wider pulse bg-red-500 rounded-full hover:bg-red-700 focus:outline-none focus:shadow-outline"
                        type="submit"
                        style={{ fontFamily: "Bangers, cursive" }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WebReviewForm;
